import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Card,
  Typography,
  CardContent,
} from "@mui/material";
import Loading from "../../../components/general/Loading";
import { confirmEmail, logout, resetPassword } from "../../../store/actions/authActions";
import { useNavigate  } from "react-router-dom";
import { getUrlParam } from "../../../utils/general";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';

const VerifyEmail: React.FC = () => {

	
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
//   const [captcha, setCaptcha] = useState("");
  const [processing, setProcessing] = useState(false);

  const [emailConfirmed, setEmailConfirmed] = useState(false);
//   const recaptchaRef = useRef<ReCAPTCHA>(null);


  const token = getUrlParam('token')


  const onSubmit = () => {
      setProcessing(true);
      dispatch(
        confirmEmail(
          { token },
          (response: any) => {
            setProcessing(false);
            setEmailConfirmed(true); 
          },
          () => {
            enqueueSnackbar(
              "Something went wrong.",
              { variant: "error" }
            );  
            setProcessing(false);
          }
        )
      );
  };

  return (
    <>
        {!emailConfirmed && <div>
      <Loading loading={processing} />
      <Card style={{ width: 400 }}>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Typography color="textSecondary" gutterBottom variant="h5">
            Verify Email
          </Typography>



          <Button
            onClick={() => onSubmit()}
            style={{ marginTop: "2rem" }}
            variant="outlined"
          >
            Verify Email
          </Button>
        </CardContent>
      </Card>
    </div>}

    {emailConfirmed && <div>
        <Card style={{ width: 400 }}>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Typography color="textSecondary" gutterBottom variant="h5">
            Email Confirmed, Please Login on your Mobile Device.
          </Typography>
          <div onClick={() => window.open("empoweredhealth://open", "_blank")} style={{cursor: 'pointer', width: '100%', textAlign: 'center'}}>
          <SendToMobileIcon style={{fontSize: 32}}/><br />
          <Button variant={'outlined'}>Launch App</Button>
            </div>
        </CardContent>
      </Card>
        </div>}
    </>


  );
};

export default VerifyEmail;
